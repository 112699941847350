import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';

import 'bootstrap/dist/css/bootstrap.css';
import Layout from './components/layout';




// ==============================

ReactDOM.render(
  <Layout />,
  document.getElementById('root')
);
